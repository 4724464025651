import React from 'react';
import { Alert, Card, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import { useAutoRefresh } from '../useAutoRefresh';
import { PostType } from '../types/PostType';
import SinglePostComponent from './SinglePostComponent';

const Component = () => {

    const { data, error } = useAutoRefresh(async () => {
        const fetchURL = process.env.REACT_APP_SERVERLESS_URL + '/posts';
        const response = await fetch(fetchURL);
        const json = await response.json();
        if (json.ErrorCode && json.ErrorMessage) {
            throw new Error(json.ErrorMessage);
        }

        json.reverse();
        return json as PostType[];
    }, [], 250);

    if (error) {
        return (<Grid item xs={12}><Alert severity="error">An error occured fetching posts.</Alert></Grid>)
    }

    if (!data) {
        return (<Grid item xs={12}><LinearProgress /></Grid>);
    }

    if (data.length === 0) {
        return (
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography align="center" style={{margin: 0}}>There are no posts.</Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    return (
        <>
            {data.map(d => <SinglePostComponent post={d} />)}
        </>
    );

}

export default Component;