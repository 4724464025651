import React from 'react';
import { Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';
import { UserContext } from '../UserContext';

const Component = () => {

    const {setter} = React.useContext(UserContext);

    const [textVal, setTextVal] = React.useState('');

    const onclick = React.useCallback(() => {
        setter(textVal);
    }, [setter, textVal]);

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={"Log In"} />
                <Divider />
                <CardContent>
                    <p>You need to log in to be able to make new posts.</p>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            <TextField fullWidth={true} value={textVal} onChange={e => setTextVal(e.target.value)} id="logInTextButton" variant="outlined" label="Username" />
                        </Grid>

                        <Grid item>
                            <Button onClick={onclick} variant="outlined">Log in</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Component;