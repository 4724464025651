import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

const Component = () => (
    <Grid item xs={12}>
        <Card>
            <CardContent>
                <Typography align="center" variant="h4">Social Networking</Typography>
                <Typography align="center" variant="h5">Cloudflare General Assignment</Typography>
                <Typography align="center" variant="h6">By Bentley Carr</Typography>
            </CardContent>
        </Card>
    </Grid>
);

export default Component;