import React from 'react';
import { UserContext } from '../UserContext';
import LogInComponent from './LogInComponent';
import NewPostComponent from './NewPostComponent';

const Component = () => {

    const {val} = React.useContext(UserContext);

    if (!val) {
        return <LogInComponent />
    }

    return <NewPostComponent />

}

export default Component;