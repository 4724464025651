import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

const REFRESH_INTERVAL = 1000;

export const RelativeTimeComponent = ({date}: {date: number}) => {
    const [text, setText] = useState<string>("");
    const luxonTime = useMemo(() => DateTime.fromMillis(date), [date]);

    const refreshText = useCallback(() => {
        let text = luxonTime.toRelative({style: 'short'}) || "";
        setText(text);
    }, [luxonTime]);
    
    useEffect(() => {
        if (REFRESH_INTERVAL > 0) {
            const interval = setInterval(refreshText, REFRESH_INTERVAL);
            return () => clearInterval(interval);
        }
    }, [refreshText]);

    return <>{text}</>;
}