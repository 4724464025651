import { createTheme, Grid, responsiveFontSizes, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import './App.css';
import FeedComponent from './components/FeedComponent';
import TitleComponent from './components/TitleComponent';
import TopPartComponent from './components/TopPartComponent';
import { UserContext } from './UserContext';

const theme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: '#F48120'
        }
    }
}), {});

function App() {

    const [username, setUsername] = useState<null | string>(null);

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <UserContext.Provider value={{ val: username, setter: setUsername }}>
                    <Grid container spacing={1}>
                        <TitleComponent />
                        <TopPartComponent />
                        <FeedComponent />
                    </Grid>
                </UserContext.Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
