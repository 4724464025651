import React from 'react';
import { Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';
import { UserContext } from '../UserContext';
import { v4 as uuidv4 } from 'uuid';

const Component = () => {

    const {val} = React.useContext(UserContext);

    const [titleVal, setTitleVal] = React.useState('');
    const [contentVal, setContentVal] = React.useState('');

    const onclick = React.useCallback(() => {
        const url = process.env.REACT_APP_SERVERLESS_URL + '/posts';

        if (titleVal === '' || contentVal === '') {
            return;
        }

        const newPost = {
            title: titleVal,
            content: contentVal,
            username: val,
            date: Date.now(),
            id: uuidv4()
        };

        fetch(url, {method: 'POST', body: JSON.stringify(newPost)}).then();

        setTitleVal('');
        setContentVal('');
    }, [titleVal, contentVal, val]);


    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader title={"New Post"} />
                <Divider />
                <CardContent>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            <TextField fullWidth={true} value={titleVal} onChange={e => setTitleVal(e.target.value)} id="logInTextButton" variant="outlined" label="Title" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth={true} multiline={true} minRows={3} value={contentVal} onChange={e => setContentVal(e.target.value)} id="logInTextButton" variant="outlined" label="Content" />
                        </Grid>
                        <Grid item>
                            <Button onClick={onclick} variant="outlined">Post!</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Component;