import React from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, IconButton, Slide, Typography } from '@mui/material';
import { PostType } from '../types/PostType';
import { RelativeTimeComponent } from './RelativeTimeComponent';
import { UserContext } from '../UserContext';
import DeleteIcon from '@mui/icons-material/Delete';

const Component = ({ post }: { post: PostType }) => {

    const { val } = React.useContext(UserContext);

    const onclick = React.useCallback(() => {
        const url = process.env.REACT_APP_SERVERLESS_URL + '/posts';
        fetch(url, { method: 'DELETE', body: post.id }).then();
    }, [post.id]);

    return (
        <Slide direction="down" in={true} mountOnEnter>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={post.title} subheader={<>{`by ${post.username} `}{post.date && (<RelativeTimeComponent date={post.date} />)}</>}
                        action={(val === post.username) && <IconButton component="a" onClick={onclick}><DeleteIcon /></IconButton>} />
                    <Divider />
                    <CardContent>
                        <Typography>{post.content}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Slide>
    );
};

export default Component;